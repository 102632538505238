<template>
  <div class="container kuan ">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="container kuan flex64">
        <div class="main">
          <div class="player listmain type-f3d f3d game-f3d game-f3d-2zh">

            <!-- <div class="kuaixuan_wrap" v-if="dop==1">
              <section class="kx_item san">

                <section class="kx_right">
                  <div class="kx_row" :class="{shang:iIndex<selNumberData.length-1,disable:item.disable}" v-for="(item,iIndex) in selNumberData" :key="item.title">
                    <em>{{item.title}}</em>
                    <span v-for="(kn,index) in item.arr" @click="setNumSel(item,kn)" :class="{selected:kn.selected}" :key="item.title+index">{{kn.num}}</span>

                
                  </div>

                </section>

              </section>

            </div>
            <div class="kuaixuan_wrap p2" v-if="dop==2">
              <section class="kx_item san">

                <section class="kx_right">
                  <div class="kx_row" :class="{shang:iIndex<selNumberData1.length-1}" v-for="(item,iIndex) in selNumberData1" :key="item.title">
                    <em>{{item.title}}</em>
                    <span v-for="(kn,index) in item.arr" @click="setNumSel1(item,kn)" :class="{selected:kn.selected}" :key="item.title+index">{{kn.num}}</span>
 
                  </div>

                </section>

              </section>

            </div> -->
          </div>
          <!-- 注单号码 -->
          <div class="player listmain  tuoduopeng" :class="dop==1?'h2zd_l':'h2zd_h'">
            <div class="data">
              <h3>注单号码 <span>共 <em class="red">{{resultData.length}} </em>种组合 </span></h3>

              <!-- <div class="dantuo-li">

          </div> -->
              <div class="dantuo-sc " :class="dop==1?'h2zd_ld':'h2zd_hd'">
                <div>
                  <span v-for="(item,index) in resultData" :key="index">{{item.label}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="other">
          <!-- <div class="zezhao" v-if="zezhao||weihu"></div> -->
          <div class="right-com">

            <div style="height:1px"></div>
            <section class="other_row">
              <span class="font_bold">定位置</span>
              <span class="inlineblock" style="width:3px"></span>
              <input type="radio" v-model="dcq" value="chu" @change="dcqChange" class="t2">除
              <input type="radio" v-model="dcq" value="qu" @change="dcqChange" class="t2">取
              <span class="inlineblock" style="width:30px"></span>
              <span class="font_bold">配数全转</span>
              <span class="inlineblock" style="width:3px"></span>
              <input type="radio" v-model="pcq" value="chu" @change="pcqChange" class="t2">除
              <input type="radio" v-model="pcq" value="qu" @change="pcqChange" class="t2">取
            </section>
            <section class="other_row"  v-if="dop==1">
         
              <div v-for="(item,index) in selNumberInput" style="display:inline-block" :key="item.title+111">
              <span class="font_bold">{{ item.title }}</span>
              <span class="inlineblock"  style="width:3px"></span>
              <input class="w80" :disabled="selNumberData[index].disable" type="number" v-model="item.numbervalue">
              <span class="inlineblock"  style="width:3px"></span>
            </div>
         
           
            </section>
            <section class="other_row"  v-if="dop==2">
         
         <div v-for="(item,index) in selNumberInput1" style="display:inline-block" :key="item.title+111">
         <span class="font_bold">{{ item.title }}</span>
         <span class="inlineblock"  style="width:3px"></span>
         <input class="w80" :disabled="selNumberData[index].disable" type="number" v-model="item.numbervalue">
         <span class="inlineblock"  style="width:3px"></span>
       </div>
    
      
       </section>
            <section class="other_row ">
              <!-- ----------------------------------------------------------------------- -->
              <span class="font_bold">合分</span>
              <input type="checkbox" class="t2" :disabled="item.disable" :checked="item.selected" @change="dwChange($event,item,index,'')" v-for="(item,index) in dwhefenArr" :key="index+123">
              <span class="inlineblock" style="width:4px"></span>
              <input type="number" class="w60" v-model="dwhenfennumInput">
              <!-- -------------------------------------------------------- -->
              <span class="inlineblock" style="width:4px"></span>
              <input type="checkbox" class="t2" :disabled="item.disable" :checked="item.selected" @change="dwChange($event,item,index,1)" v-for="(item,index) in dwhefenArr1" :key="index+456">
              <span class="inlineblock" style="width:4px"></span>
              <input type="number" class="w60" v-model="dwhenfennumInput1">
                <!-- -------------------------------------------------------- -->
                <span class="inlineblock" style="width:4px"></span>
              <input type="checkbox" class="t2" :disabled="item.disable" :checked="item.selected" @change="dwChange($event,item,index,2)" v-for="(item,index) in dwhefenArr2" :key="index+789">
              <span class="inlineblock" style="width:4px"></span>
              <input type="number" class="w60" v-model="dwhenfennumInput2">
                <!-- -------------------------------------------------------- -->
                <span class="inlineblock" style="width:4px"></span>
              <input type="checkbox" class="t2" :disabled="item.disable" :checked="item.selected" @change="dwChange($event,item,index,3)" v-for="(item,index) in dwhefenArr3" :key="index+169">
              <span class="inlineblock" style="width:4px"></span>
              <input type="number" class="w60" v-model="dwhenfennumInput3">
             
            </section>
            <section class="other_row">
              <span class="font_bold">不定位合分</span> <input class="t2" type="checkbox" :checked="condition.bdwhf==2" :value="2" @change="hefenChange">
              两数合 
              <input type="number" class="w80" v-model="bdwhenfennumInput">
              <!-- <span class="dwhefen_num" v-for="(kn,index) in bdwhenfenNumArr" @click="setdwhefenNum1(kn)" :class="{selected:kn.selected}" :key="index+110">{{kn.label}}</span> -->
            </section>
            <section class="other_row">
              <span class="font_bold">全转</span>
              <span class="inlineblock"  style="width:3px"></span>
              <input type="number" @change="qzchange" v-model="condition.qz" class="w70">
              <span class="font_bold">上奖</span>
              <span class="inlineblock" style="width:3px"></span>
              <input type="number" @change="qzchange" v-model="condition.sj" class="w70">
              <span class="font_bold">排除</span>
              <span class="inlineblock" style="width:3px"></span>
              <input type="number" @change="qzchange" v-model="condition.pc" class="w70">
              <span class="font_bold">乘号位置</span>
              <span class="inlineblock" style="width:3px"></span>
            <input type="checkbox" class="t2" :disabled="item.disable" :checked="item.selected" @change="chwzChange($event,item,index)" v-for="(item,index) in chwzArr" :key="index">

            </section>
        
            <section class="other_row">
              <input type="checkbox" @change="hanchange" :checked="condition.hancq=='chu'"  value="chu" class="t2">除
              <input type="checkbox" @change="hanchange" :checked="condition.hancq=='qu'"  value="qu" class="t2" >取
              <span class="inlineblock" style="width:8px"></span>
              二字定 <span class="font_bold">含</span>

              <input  type="number"  @change="qzchange" v-model="condition.haninput" class="w70">
              二字定 <span class="font_bold">复式</span>
              <input type="text" @change="qzchange" v-model="condition.fushiinput" class="w70">
            </section>
            <section class="other_row">
              <input type="checkbox" value="chu" @change="scchange" :checked="condition.sc=='chu'" class="t2">除
              <input type="checkbox" value="qu" @change="scchange" :checked="condition.sc=='qu'" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">双重</span>)
         
            </section>
            <section class="other_row">
              <input type="checkbox" @change="xd2change" :checked="condition.xd2=='chu'" class="t2" value="chu">除
              <input type="checkbox" @change="xd2change" :checked="condition.xd2=='qu'" class="t2" value="qu">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">二兄弟</span>)
          
            </section>
            <section class="other_row">
              <input type="checkbox" class="t2" @change="dschange" :checked="condition.ds=='chu'" value="chu">除
              <input type="checkbox" class="t2" @change="dschange" :checked="condition.ds=='qu'" value="qu">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">对数</span>)

              <input type="text" v-model="condition.dsinput1" @change="dsinputchange($event,1)" :maxlength="2" class="w50">
              <input type="text" v-model="condition.dsinput2" @change="dsinputchange($event,2)" :maxlength="2" class="w50">
              <input type="text" v-model="condition.dsinput3" @change="dsinputchange($event,3)" :maxlength="2" class="w50">
              <input type="text" v-model="condition.dsinput4" @change="dsinputchange($event,4)" :maxlength="2" class="w50">
              <input type="text" v-model="condition.dsinput5" @change="dsinputchange($event,5)" :maxlength="2" class="w50">
            </section>
            <section class="other_row">  
              <div class="w240">
              <input type="checkbox" @change="danchange" :checked="condition.dancq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="danchange" :checked="condition.dancq=='qu'" class="t2" value="qu">取
              <span class="inlineblock" style="width:4px"></span>
              (<span class="font_bold">单</span>)
              <span class="inlineblock" style="width:4px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" :disabled="item.disable" v-for="(item,index) in danArr" :key="index+200">
           <span class="green">{{cqLabel[condition.dancq]}}   {{danLabel[danKey]}}</span> 
         </div>

           <!-- ------------------------------------- -->
           <span class="inlineblock" style="width:30px"></span>
           <div class="w240">
           <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:4px"></span>
              (<span class="font_bold">双</span>)
              <span class="inlineblock" style="width:4px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" :disabled="item.disable" v-for="(item,index) in shuangArr" :key="index+300">
           <span class="green">{{cqLabel[this.condition.shuangcq]}}   {{shuangLabel[this.shuangKey]}}</span> 
           </div> 
          </section>
            <!-- <section class="other_row">
              <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">双</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" :disabled="item.disable" v-for="(item,index) in shuangArr" :key="index+300">
           <span class="green">{{cqLabel[this.condition.shuangcq]}}   {{shuangLabel[this.shuangKey]}}</span> 
       
           
            </section> -->
            <!-- <section class="other_row">
              <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='chu'" value="chu"  class="t2">除
              <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">小</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" :disabled="item.disable" v-for="(item,index) in xiaoArr" :key="index+400">
           <span class="green">{{cqLabel[this.condition.xiaocq]}}   {{xiaoLabel[this.xiaoKey]}}</span> 
            </section> -->
           
            <section  class="other_row">
              <div class="w240">
              <input type="checkbox" @change="dachange" :checked="condition.dacq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="dachange" :checked="condition.dacq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:4px"></span>
              (<span class="font_bold">大</span>)
              <span class="inlineblock" style="width:4px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" :disabled="item.disable" v-for="(item,index) in daArr" :key="index+500">
           <span class="green">{{cqLabel[this.condition.dacq]}}   {{daLabel[this.daKey]}}</span> 
          </div>

           <!-- --- -->
           <span class="inlineblock" style="width:30px"></span>
           <div class="w240">
           <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='chu'" value="chu"  class="t2">除
              <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:4px"></span>
              (<span class="font_bold">小</span>)
              <span class="inlineblock" style="width:4px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" :disabled="item.disable" v-for="(item,index) in xiaoArr" :key="index+400">
           <span class="green">{{cqLabel[this.condition.xiaocq]}}   {{xiaoLabel[this.xiaoKey]}}</span>
           </div>
          </section>
            <section  >
              
              <div  class="btnaa">
                <span  class="button" @click="filterAllConditionData">生成</span>
                <span  class="button" @click="resetAllCondition">重置</span>
              </div>
              </section>

          </div>
        </div>
      </div>
  
      <yushe />

    </div>

  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import kxmixins from "../mixins/kxmixinspl3";
export default {
  name: "2zdkx",
  props: [""],
  mixins: [mixins, kxmixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      groupnames:'2zdw',
      yxLen: 2,
      chLen:2,
 
      rowsData: [],
      baishi: { title: "佰拾-口口X", arr: [] },
      baige: { title: "佰个-口X口", arr: [] },
    
      shige: { title: "拾个-X口口", arr: [] },
      allData: [],
      oddData1: [],
      wzIndex: {
     
        bai: 0,
        shi: 1,
        ge: 2,
      },
      selNumberInput1:[{title:'取',numbervalue:''},{title:'配',numbervalue:''}],
       //   配数全转  二字定
       selNumberData1: [
        {
          title: "取",
          eng: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "配",
          eng: "pei",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
      ],
      selNumberData: [
        
        {
          title: "佰",
          eng: "bai",
          disable: false,
          chuqu: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "拾",
          eng: "shi",
          disable: false,
          chuqu: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "个",
          eng: "ge",
          disable: false,
          chuqu: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
      ],
      selNumberInput:[
      
        {numbervalue:'',title:'佰'},
        {numbervalue:'',title:'拾'},
        {numbervalue:'',title:'个'},
      ],
      wzIndex1: {
       
        0: "bai",
        1: "shi",
        2: "ge",
      },
      daLabel:{
       
        baishi:'大大X',
        baige:'大X大',
        shige:'X大大',
        '':''
      },
      xiaoLabel:{
      
        baishi:'小小X',
        baige:'小X小',
        shige:'X小小',
        '':''
      },
      shuangLabel:{
       
        baishi:'双双X',
        baige:'双X双',
        shige:'X双双',
        '':''
      },
      danLabel:{
       
        baishi:'单单X',
        baige:'单X单',
        shige:'X单单',
        '':''
      },
     
    };
  },
  watch: {
   
    pan(val) {},
    rowsData: {
      deep: true,
      handler(val) {
        
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
           
              if (item.money) {
                let obj = {
                  label: item.label,
                  Odds: item.Odds,
                  title: '二字定',
                  id: item.ResultID,
                  money: Number(item.money),
                };
                arr.push(obj);
              
              }
            
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        this.allData = [];
       
        let labelArr4 = [],
          labelArr5 = [],
          labelArr6 = [];
        for (let index = 0; index < 100; index++) {
          let label5 = this.checkbaishi(index); //佰拾
          let label4 = this.checkbaige(index); //佰个
         
          let label6 = this.checkshige(index); //十个
         
          labelArr4.push(label4);
          labelArr5.push(label5);
          labelArr6.push(label6);
        }
     
        // 佰拾
        let arr4 = this.filterData(resAArr, 4035, 4134, labelArr5);
        this.baishi.arr = arr4;
        // 佰个
        let arr5 = this.filterData(resAArr, 4135, 4234, labelArr4);
        this.baige.arr = arr5;
        // 十个
        let arr6 = this.filterData(resAArr, 4235, 4334, labelArr6);
        this.shige.arr = arr6;
        this.allData = [ ...arr4, ...arr5, ...arr6];
      
        // console.log(arr4,arr5,arr6,455)
        // console.log(this.allData, "所有数据");
      },
    },
  },

  created() {
    // 假数据--------------
    // let erziding = [];
    // for (let index = 5529; index < 6129; index++) {
    //   erziding.push({
    //     ResultID: index,
    //     Odds: 9.989,
    //   });
    // }
    // this.oddData1 = erziding;
  },

  methods: {
    checkNum(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      return newcount;
    },

    checkqianbai(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = str[0] + str[1] + "XX";
      return label;
    },
    checkqianshi(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = str[0] + "X" + str[1] + "X";
      return label;
    },
    checkqiange(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = str[0] + "XX" + str[1];
      return label;
    },
    checkbaige(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label =  str[0] + "X" + str[1];
      return label;
    },
    checkbaishi(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label =  str[0] + str[1] + "X";
      return label;
    },
    checkshige(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = "X" + str[0] + str[1];
      return label;
    },
  },
};
</script>
<style  scoped>
/* ------------------------other_row */

.other_row {
  width: 100%;
  /* height: 40px;
  line-height: 40px; */
  padding: 0 4px;
  box-sizing: border-box;
  /* background: #edf4fe; */
}
input.t2 {
  position: relative;
  top: 2px;
  margin-right: 2px;
}
/* ------------------------------------------- */
.peishu_sec {
  margin-left: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  background: #edf4fe;
}
.xuan_way {
  height: 20px;
  display: flex;
  justify-content: center;
}
.kx_row.da {
  width: 70%;
  height: 100%;
  flex-wrap: wrap;
}
span.dwhefen_num {
  margin-left: 5px;
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: #f7f7f7;
  font-size: 13px;
  border: 1px solid rgb(187, 185, 185);
  font-weight: bold;
  border-radius: 50%;
}
.kx_row span {
  margin-left: 4px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f7f7f7;
  font-size: 15px;
  border: 1px solid rgb(187, 185, 185);
  font-weight: bold;
  border-radius: 5px;
}

.kx_right {
  flex: 1;
  height: 100%;
}

.kx_item.san {
  width: 100%;
}
.kx_item.er {
  flex: 1;
}
.kx_left {
  width: 110px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-right: 1px solid lightgray;
}

.kx_item {
  height: 100%;
  display: flex;
}

.kuaixuan_wrap {
  padding-bottom: 2px;
  display: flex;
  margin-top: 1px;
  width: 100%;
  height: 160px;
  border: 1px solid lightgray;
}
li.liqx i,
li.w2 i {
  font-size: 20px;
  color: #666;
}
li.w2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.liqx {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listmain > .data > ul.custom_ul.kuaixuan {
  height: 26px;
}
.listmain > .data > ul.custom_ul {
  width: 98%;
  height: 260px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data {
  /* width: 90%; */
}
.listmain .data ul li {
  width: 10%;
  height: 26px;
}
.listmain .data ul li.w2 {
  width: 2%;
}
</style>